export const settings = {
  dots: false, // Enable navigation dots
  infinite: true, // Infinite scrolling
  speed: 650, // Animation speed in ms
  slidesToShow: 3, // Number of slides visible
  slidesToScroll: 1, // Number of slides to scroll
  autoplay: true, // Enable auto sliding
  autoplaySpeed: 3000, // Interval between slides
  pauseOnHover: true, // Pause autoplay on hover
  centerMode: true,
  responsive: [
    {
      breakpoint: 768, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 card on smaller screens
      },
    },
    {
      breakpoint: 1024, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 cards on medium screens
      },
    },
  ],
};
