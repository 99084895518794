import React from "react";
import Slider from "react-slick";
import { eventCards } from "./constants";
import EventCard from "./EventCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./PreviousEvents.module.scss";
import { settings } from "./PreviousEvents.data";

const PreviousEvents = () => {
  return (
    <div className={styles.section}>
      <h2 className={styles.title}>Previous Events</h2>
      <Slider {...settings} className={styles.slider}>
        {eventCards.map((card, index) => (
          <div key={index} className={styles.slide}>
            <EventCard {...card} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PreviousEvents;
