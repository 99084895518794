import { ReactNode } from "react";

import ChartIcon from "assets/icons/UpcomingEventsIcons/Chart";
import CoinsIcon from "assets/icons/UpcomingEventsIcons/Coins";
import DiamondIcon from "assets/icons/UpcomingEventsIcons/Diamond";
import GlobeIcon from "assets/icons/UpcomingEventsIcons/Globe";
import BackgroundPictureAbuDhabi from "./images/Background-Picture-Abu-Dhabi.jpg";
import BackgroundPictureAspen from "./images/Background-Picture-Aspen.jpg";
import BackgroundPictureAustin from "./images/Background-Picture-Austin.jpg";
import BackgroundPictureDubai from "./images/Background-Picture-Dubai.jpg";
import BackgroundPictureNY from "./images/Background-Picture-NY.jpg";
import BackgroundPicturePalmBeach from "./images/Background-Picture-Palm-Beach.jpg";
import ForegroundPictureNY from "./images/Foregroun-Picture-NY.jpg";
import ForegroundPicturePalmBeach from "./images/Foreground-Picture-PALM-BEACH.jpg";
import ForegroundPictureAbuDhabi from "./images/Foreground-Picture-Abu-Dhabi.jpg";
import ForegroundPictureAspen from "./images/Foreground-Picture-Aspen.jpg";
import ForegroundPictureAustin from "./images/Foreground-Picture-Austin.jpg";
import ForegroundPictureDubai from "./images/Foreground-Picture-Dubai.jpg";

interface ICard {
  id: number;
  title: string;
  placeAndDate: string;
  description: string;
  imageUrl: string;
  icon: ReactNode;
  backgroundImageUrl: string;
}

export const cards: ICard[] = [
  {
    id: 1,
    title: "Aspen: Opulence at Altitude",
    placeAndDate: "Aspen, Colorado | February 13, 2025",
    description:
      "An intimate evening at the historic Hotel Jerome as we celebrate the next chapter for LUXUS and share an exciting opportunity to invest in LEAAF.",
    imageUrl: ForegroundPictureAspen,

    icon: <CoinsIcon />,
    backgroundImageUrl: BackgroundPictureAspen,
  },
  {
    id: 2,
    title: "Palm Beach Yacht Week",
    placeAndDate: "Palm Beach, FL | March 19 – 23, 2025",
    description:
      "An exclusive cocktail party timed with the Palm Beach Boat Show, offering private investors a sophisticated setting to indulge in the world of superyachts.",
    imageUrl: ForegroundPicturePalmBeach,
    icon: <DiamondIcon />,
    backgroundImageUrl: BackgroundPicturePalmBeach,
  },
  {
    id: 3,
    title: "Dubai World Cup",
    placeAndDate: "Dubai | April 5, 2025",
    description:
      "A private event for investors and the LUXUS network ahead of the World Cup, showcasing our relationship with the thoroughbred community.",
    imageUrl: ForegroundPictureDubai,
    icon: <GlobeIcon />,
    backgroundImageUrl: BackgroundPictureDubai,
  },
  {
    id: 4,
    title: "New York",
    placeAndDate: "New York, NY | May 2025",
    description:
      "LUXUS welcomes you for an intimate discussion on fashion and luxury investing as we continue to grow LEAAF.",
    imageUrl: ForegroundPictureNY,
    icon: <ChartIcon />,
    backgroundImageUrl: BackgroundPictureNY,
  },
  {
    id: 5,
    title: "Austin",
    placeAndDate: "Austin, TX | October 2025",
    description:
      "Join us for an evening of meaningful connections with LUXUS investors, family offices, UHNW and luxury enthusiasts from across Southern Texas.",
    imageUrl: ForegroundPictureAustin,
    icon: <ChartIcon />,
    backgroundImageUrl: BackgroundPictureAustin,
  },
  {
    id: 6,
    title: "Abu Dhabi World Finance Week",
    placeAndDate: "Abu Dhabi | Dec 09 - Dec 12, 2025",
    description:
      "To close out the year, LUXUS will host a panel on wealth tech and luxury investing followed by an exclusive investor dinner.",
    imageUrl: ForegroundPictureAbuDhabi,
    icon: <ChartIcon />,
    backgroundImageUrl: BackgroundPictureAbuDhabi,
  },
];
