import { CustomButton } from "components/UIComponents/Button";
import { useSwiper } from "swiper/react";

import styles from "./UpcomingEvents.module.scss";

type JoinWaitlistProps = {
  isModalOpen: boolean;
  handleModal: () => void;
};

const JoinWaitlist = ({ isModalOpen, handleModal }: JoinWaitlistProps) => {
  const swiper = useSwiper();

  const handleSlider = () => {
    if (isModalOpen) {
      swiper.autoplay.start();
    } else {
      swiper.autoplay.stop();
    }
    handleModal();
  };

  return (
    <CustomButton
      style={{ display: "none" }}
      btnStyle="buttonWhiteTillText"
      onClick={handleSlider}
    >
      <span className={styles.buttonText}>GET ON THE LIST</span>
    </CustomButton>
  );
};

export default JoinWaitlist;
