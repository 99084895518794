import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import cs from "classnames";

import styles from "./UpcomingEvents.module.scss";
import { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./slider.scss";
import { cards } from "./constants";
import { Card } from "./Card";
import {
  getRealSlideIndex,
  SwiperSlideControls,
} from "components/Swiper/SwiperSlideControls";
import { Box, Modal, Slide, useMediaQuery } from "@mui/material";
import ModalJoin from "../ModalJoin/ModalJoin";
import { HubspotForms } from "../ModalJoin/JoinForm/JoinForm";
import JoinWaitlist from "./JoinWaitlist";

const getHubspotFormTypeByRealIndex = (index: number) => {
  const realIndex = getRealSlideIndex(index, 4);

  switch (realIndex) {
    case 1:
      return HubspotForms.AUSTIN;
    case 2:
      return HubspotForms.BREEDERS_CUP;
    case 3:
      return HubspotForms.ABU_DHABI;
    case 4:
      return HubspotForms.PALM_BEACH;
    default:
      return HubspotForms.GET_NOTIFIED;
  }
};

const UpcomingEvents: React.FC = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLessThanDesktop = useMediaQuery("(max-width: 1170px)");
  const isLargeScreen = useMediaQuery("(min-width: 1440px)");

  const handleModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.backgroundImageContainer}>
        {cards.map((card, index) => (
          <div
            key={index}
            className={cs(styles.backgroundImage, {
              [styles.active]: slideIndex === index,
            })}
          >
            <img
              src={card.backgroundImageUrl}
              alt={""}
              width={2000}
              height={800}
            />
          </div>
        ))}
      </div>
      <div className={styles.sliderContainer}>
        <h2 className={styles.title}>Upcoming Events</h2>
        <Swiper
          spaceBetween={50}
          loop={true}
          slidesPerView={isLessThanDesktop ? 1 : isLargeScreen ? 4 : 3}
          centeredSlides={true}
          initialSlide={0}
          speed={1000}
          autoplay={{ delay: 2000, disableOnInteraction: true }}
          modules={[Autoplay]}
          onRealIndexChange={(swiper) => setSlideIndex(swiper.realIndex)}
          className="upcomingEventsSlider"
        >
          {!isLessThanDesktop &&
            cards.map((card, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`slide-content ${
                    slideIndex === index ? "active" : ""
                  }`}
                >
                  <Card
                    title={card.title}
                    description={card.description}
                    placeAndDate={card.placeAndDate}
                    icon={card.icon}
                    imageUrl={card.imageUrl}
                    isActive={slideIndex === index}
                  />
                </div>
              </SwiperSlide>
            ))}
          {isLessThanDesktop &&
            cards.map((card, index) => (
              <SwiperSlide key={index}>
                <div className={`slide-content`}>
                  <Card
                    title={card.title}
                    description={card.description}
                    placeAndDate={card.placeAndDate}
                    icon={card.icon}
                    imageUrl={card.imageUrl}
                    isActive={true}
                  />
                </div>
              </SwiperSlide>
            ))}
          <div className={styles.controlBlock}>
            <p className={styles.invitation}>By Invitation Only</p>
            <JoinWaitlist isModalOpen={isModalOpen} handleModal={handleModal} />
            <SwiperSlideControls
              slideIndex={slideIndex}
              totalSlides={cards.length}
            />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isModalOpen}
            onClose={handleModal}
          >
            <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  bottom: "0",
                  width: "100%",
                }}
              >               
                <ModalJoin
                  handleModal={handleModal}
                  formName={getHubspotFormTypeByRealIndex(slideIndex)}
                />
              </Box>
            </Slide>
          </Modal>
        </Swiper>
      </div>
    </section>
  );
};

export default UpcomingEvents;
