import FirstImage from "./images/1.jpg";
import SecondImage from "./images/2.jpg";
import ThirdImage from "./images/3.png";

interface EventCard {
  id: number;
  title: string;
  performedAt: string;
  image: string;
}

export const eventCards: EventCard[] = [
  {
    id: 1,
    title: "Singapore",
    performedAt: "September 13, 2023",
    image: FirstImage,
  },
  {
    id: 2,
    title: "Austin",
    performedAt: "October 11, 2023",
    image: SecondImage,
  },
  {
    id: 3,
    title: "Palm Beach",
    performedAt: "January 30, 2024",
    image: ThirdImage,
  },
  {
    id: 4,
    title: "Austin",
    performedAt: "October 22, 2024",
    image:
      "https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/2_main.jpg",
  },
  {
    id: 5,
    title: "Delmar",
    performedAt: "November 1, 2024",
    image:
      "https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/3_main.jpg",
  },
  {
    id: 6,
    title: "Abu Dhabi",
    performedAt: "November 1, 2024",
    image:
      "https://d1p3kxhg87s54m.cloudfront.net/website-images/experiences-website/upcoming-events-slider/1_main.jpg",
  },
];
